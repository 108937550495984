import React, { useCallback, useContext, useState } from 'react';
import Text from 'components/basic/Typography/Text';
import Field from 'components/basic/Field/Field';
import moment from 'moment';
import AvatarUpload from 'components/basic/AvatarUpload/AvatarUpload';
import { Button } from 'components/basic';
import classnames from 'classnames';
import ImageWithFallback from 'components/basic/ImageWithFallback/ImageWithFallback';
import { Input, Modal, Tooltip, notification } from 'antd';
import useApi from 'hooks/useApi';
import { updateDriver } from 'services/message.service';
import useMount from 'hooks/useMount';
import { useAuth0 } from '@auth0/auth0-react';
import useModal from 'hooks/useModal';
import { AuthUserContext } from 'components/context/AuthUserContext';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';

const DriverSummary = ({ data, page, expanded, refreshData, fetchDriver }: any) => {
    const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
    const [isPhoneEditable, setIsPhoneEditable] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(data.phone_number || '');
    const [claims, setClaims] = React.useState<any>(null);
    const confirmModal = useModal();
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return getPermissions(userRole, page)?.includes(Accessibility.ALL) || 
            getPermissions(userRole, page)?.includes(Accessibility.EDIT) ||
            getPermissions(userRole, page)?.includes(Accessibility.EDIT_DRIVER_MOBILE_NUMBER)
    }
    
    const {
        request: updateDriverRequest,
        loading: updatingDriver,
        result: updateDriverResult,
    } = useApi({
        api: updateDriver,
    });

    const handleCancel = () => {
        setPhoneNumber(data.phone_number);
        setIsPhoneEditable(false);
    };

    const handleSubmit = useCallback(async () => {
        const { id, service_types, fleets, account_status, account_status_reason, tnv_violation, service_zone } = data;
        const res = await updateDriverRequest({
            id: id,
            body: {
                service_types: service_types?.map((s: any) => s.id),
                fleets: fleets?.map((fleet: any) => fleet.id),
                account_status: account_status,
                account_status_reason: account_status_reason,
                tnv_violation: tnv_violation,
                service_zone: service_zone,
                phone_number: phoneNumber,
            },
        });
        const { error } = res;
        if (!error) {
            notification.success({
                message: 'Success',
                description: 'Successfully Saved.',
                duration: 3,
            });
            setIsPhoneEditable(false);
            refreshData();
        } else {
            notification.error({
                message: 'Error',
                description: error.message,
                duration: 3,
            });
        }
        confirmModal.close();
    }, [data, refreshData, phoneNumber, updateDriverRequest, confirmModal]);

    useMount(() => {
        const detectUser = async () => {
            const accessToken = await getAccessTokenSilently();
            if (accessToken) {
                getIdTokenClaims().then(claims => {
                    setClaims(claims);
                });
            }
        };
        detectUser();
    });

    return (
        <div className={classnames('flex-1', expanded ? '' : 'flex gap-4 items-center')}>
            <div className={classnames('lg:flex items-center justify-between', expanded ? '' : 'flex')}>
                <div className={classnames('flex gap-4 flex-row items-center', expanded ? ' ' : '')}>
                    {page === 'trip' ? (
                        <ImageWithFallback
                            src={data.avatar_url}
                            firstName={data.first_name}
                            lastName={data.last_name}
                        />
                    ) : (
                        <AvatarUpload
                            data={data}
                            userType="driver"
                            onAvatarChange={url => {
                                data.avatar_url = url;
                            }}
                            onUploadFinish={refreshData}
                        />
                    )}

                    <Text
                        size={expanded ? 'text-lg' : 'text-md'}
                        fontWeight="font-semibold"
                        className="mb-0 transition ease-in-out break-all"
                    >
                        {data.first_name} {data.last_name}
                    </Text>
                </div>
            </div>

            <div
                className={classnames(
                    '',
                    expanded ? 'grid gap-x-6 gap-y-2 grid-cols-2 mt-4' : 'flex gap-6 items-start',
                )}
            >
                <Field label="Driver ID" value={data.public_id} copyable={expanded} />
                <div>
                    <div className="flex justify-between">
                        <Text type="label">Mobile Number</Text>
                        {!isPhoneEditable && hasActionAccess(PagesEnum.DRIVERS) && (
                            <Button
                                size="small"
                                className={!expanded ? 'ml-sm' : ''}
                                disabled={updatingDriver}
                                onClick={() => setIsPhoneEditable(true)}
                            >
                                Edit
                            </Button>
                        )}
                    </div>
                    {isPhoneEditable ? (
                        <div>
                            <Tooltip title="Enter a 10-digit phone number without the area code. Example: 9172345678">
                                <Input
                                    prefix={<Text className="font-bold">+63</Text>}
                                    value={phoneNumber?.replace('+63', '')}
                                    maxLength={10}
                                    minLength={0}
                                    status={phoneNumber === '+63' || phoneNumber?.length < 13 ? 'error' : ''}
                                    onChange={e => {
                                        const { value } = e.target;
                                        const isValidPhoneNumber = /^\d+$/.test(value);
                                        if (isValidPhoneNumber) {
                                            setPhoneNumber(`+63${value}`);
                                        } else if (value === '') {
                                            setPhoneNumber(`+63${value}`);
                                        }
                                    }}
                                />
                            </Tooltip>
                            {phoneNumber === '+63' || phoneNumber.length < 13 ? (
                                <Text color="text-red-500">Invalid Mobile Number</Text>
                            ) : null}
                            {isPhoneEditable && (
                                <div className="flex gap-2 mt-xs">
                                    <Button size="small" onClick={handleCancel} disabled={updatingDriver}>
                                        Cancel
                                    </Button>
                                    <Button
                                        size="small"
                                        type="primary"
                                        loading={updatingDriver}
                                        disabled={updatingDriver || phoneNumber === '+63' || phoneNumber.length < 13}
                                        onClick={() => confirmModal.show()}
                                    >
                                        Save
                                    </Button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <Field value={phoneNumber} copyable={expanded} className={expanded ? 'mt-sm' : ''} />
                    )}
                </div>

                {expanded && <Field className='break-all' label="First Name" value={data.first_name || '-'} />}
                {expanded && <Field className='break-all' label="Middle Name" value={data.middle_name || '-'} />}
                {expanded && <Field className='break-all' label="Last Name" value={data.last_name || '-'} />}
                <Field label="Gender" value={data.gender || '-'} />

                {expanded && (
                    <>
                        <span className="text-gray-400 uppercase mt-4">License Details</span>
                        <div></div>
                    </>
                )}

                <Field label="License Number" value={data.license_number} valueClassName='text-ellipsis break-all' />
                <Field
                    label="Expiry"
                    value={data.license_expiry_date ? moment(data.license_expiry_date).format('YYYY-DD-MM') : '-'}
                />
            </div>

            {page === 'trip' ? (
                <div className="flex mt-4">
                    <Button
                        className="w-full"
                        onClick={() => {
                            window.open(`/drivers/${data.id}/overview`, '_blank');
                        }}
                    >
                        View Driver
                    </Button>
                </div>
            ) : null}
            <Modal
                {...confirmModal}
                title="Update Mobile Number"
                onOk={handleSubmit}
                okText="Confirm"
            >{`You are about to change the mobile number of ${data.first_name || ''} ${
                data.last_name || ''
            }. Do you wish to proceed?`}</Modal>
        </div>
    );
};

export default DriverSummary;
