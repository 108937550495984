import {
    Button,
    Form,
    Input,
    Modal,
    Skeleton,
} from 'antd';
import { CustomNotification, Table, Text } from 'components/basic';
import React, { useContext, useEffect, useState } from 'react';
import Field from 'components/basic/Field/Field';
import moment from 'moment';
import './FareConfig.module.scss';
import useApi from 'hooks/useApi';
import { updateSurchargeConfig, saveFareBodyConfig } from 'services/message.service';
import { formatNumberToAmount, getOrdinalIndicator } from 'services/number.service';
import classnames from 'classnames';
import { AuthUserContext } from 'components/context/AuthUserContext';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';

export const formatFareConfigErrorMessage = (errorMessage: string) => {
    const parts: string[] = errorMessage?.split(' ');

    parts[0] = parts[0].charAt(0).toUpperCase() + parts[0].slice(1);

    const keywordIndex = parts.indexOf('distance_based_price');
    if (keywordIndex !== -1) {
        parts[keywordIndex] = `- Distance Based Prices`;
    }

    parts.forEach((part, index) => {
        const number = parseInt(part);
        if (!isNaN(number)) {
            const ordinalIndicator = getOrdinalIndicator(number);
            parts[index] = `${number}${ordinalIndicator} row`;
        }
    });

    return `${parts.join(' ')}.`;
};

const FareConfigSurge = (props: any) => {
    const [isEditable, setIsEditable] = useState(props.onCreate || false);
    const [form] = Form.useForm();
    const [dataSource, setDataSource] = useState<any[]>(props?.surge || []);
    const [dataFareSource, setDataFareSource] = useState<any[]>(props?.fare || []);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isProceeding, setIsProceeding] = useState(false); 
    const [formValues, setFormValues] = useState<any>(null);
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return getPermissions(userRole, page)?.includes(Accessibility.ALL);
    }

    
    const filteredFareData = dataSource.filter(item => item.enabled === true && item.service_type === 'passenger_four_seater');
    const filteredSurgeData = dataFareSource.filter(item =>
        item.service_type === 'passenger_four_seater' &&
        item.service_zone === 'MNL');

    console.log(filteredSurgeData, 'test data')
    const { request, loading } = useApi({
        api: updateSurchargeConfig,
    });
    const { request: requestFare, loading: fareLoading } = useApi({
        api: saveFareBodyConfig,
    });

    const showConfirmModal = async () => {
        try {
            const values = await form.validateFields();
            setFormValues(values);
            setIsModalVisible(true);
        } catch (error) {
            console.log('Validation failed:', error);
        }
    };

    const handleModalOk = async () => {
        if (!isProceeding && formValues) { // Prevent multiple clicks by checking isProceeding
            setIsProceeding(true); // Disable the button
            let body = {
                code: filteredFareData[0].code,
                name: filteredFareData[0].name,
                rules: [
                    {
                        type: filteredFareData[0].rules[0].type,
                        factors: {
                            start_time: filteredFareData[0].rules[0].factors.start_time,
                            end_time: filteredFareData[0].rules[0].factors.end_time
                        },
                        effects: [
                            {
                                "type": filteredFareData[0].rules[0].effects[0].type,
                                "operation": filteredFareData[0].rules[0].effects[0].operation,
                                "value": parseFloat(formValues.threshold)
                            }
                        ]
                    }
                ],
                geo_cells: [
                    filteredFareData[0].geo_cells[0]
                ],
                enabled: true,
                service_type: "passenger_four_seater"
            }
            let bodyFare = {
                id: props.fare.id,
                base_price: parseFloat(filteredSurgeData[0].base_price),
                driver_commission: parseFloat(filteredSurgeData[0].driver_commission),
                service_type: 'passenger_four_seater',
                service_zone: 'MNL',
                time_based_price_rate: parseFloat(formValues.value),
            }

            try {
                const result = await request({ body });
                const resultFare = await requestFare({ bodyFare });

                if (!result.error && !resultFare.error) {
                    setDataSource(prevDataSource => 
                        prevDataSource.map(item => 
                          item.id === filteredFareData[0].id ? { ...item, ...body } : item
                        )
                      );
                      setDataFareSource(prevDataFareSource => 
                        prevDataFareSource.map(item => 
                          item.id === filteredSurgeData[0].id ? { ...item, ...bodyFare } : item
                        )
                      );
                      
                    setIsEditable(!isEditable);
                    if (props.refreshList) {
                        await props.refreshList();
                    }
                    CustomNotification({
                        type: 'success',
                        message: 'Success',
                        description: 'Successfully Saved',
                    });
                } else if (result.error.customMessage) {
                    CustomNotification({
                        type: 'error',
                        message: 'Error',
                        description: formatFareConfigErrorMessage(result.error.customMessage),
                    });
                } else {
                    CustomNotification({
                        type: 'error',
                        message: 'Error',
                        description: result?.error.message,
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsModalVisible(false);
                setFormValues(null);
                setIsProceeding(false); 
            }
        }
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleCancel = () => {
        onReset();
        setDataSource(props?.surge);
        setDataFareSource(props?.fare)
        setIsEditable(false);
    };

    const onReset = () => {
        form.resetFields();
    };

    const handleCreate = async () => {
        try {
            await form.validateFields();
            if (props.handleCreate) {
                const values = form.getFieldsValue();
                let body = {
                    ...values,
                    threshold: parseFloat(values.threshold),
                    distance_based_price: dataSource?.map((data) => {
                        const { start_value } = data;
                        return {
                            start_value: parseFloat(start_value),
                        };
                    }),
                };
                props.handleCreate(body);
            }
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <div
            className={classnames(
                'rounded gap-2 flex flex-col',
                props.inModal ? '' : 'p-4 border border-dashed border-slate-100 mt-4'
            )}
        >
            <Form
                form={form}
                initialValues={{
                    threshold: filteredFareData[0].rules[0].effects[0].value,
                    value: filteredSurgeData[0].time_based_price_rate,
                }}
                onFinish={showConfirmModal}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="flex gap-2 items-center mb-4">
                    {!isEditable && hasActionAccess(PagesEnum.GENERAL_SETTINGS) && (
                        <Button
                            size="small"
                            onClick={() => {
                                setIsEditable(!isEditable);
                                setDataSource(props?.surge);
                            }}
                        >
                            Edit
                        </Button>
                    )}
                    {isEditable && (
                        <div className="flex gap-2">
                            <Button size="small" loading={loading} onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button
                                size="small"
                                htmlType="submit"
                                type="primary"
                                loading={loading}
                            >
                                Save
                            </Button>
                        </div>
                    )}
                    {!isEditable && (
                        <p className="m-0">
                            Last updated at:{' '}
                            <span className="font-semibold">
                                {filteredSurgeData[0].updated_at
                                    ? moment.utc(filteredSurgeData[0].updated_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A')
                                    : '-' || '-'}
                            </span>
                        </p>
                    )}
                </div>

                <div className="flex gap-8">
                    <div className="w-full">
                        {!isEditable ? (
                            <div className="flex-1 flex flex-row justify-between">
                                <div className='w-3/5'>
                                    <Field
                                        label="Surge Multiplier"
                                        value={
                                            props.surge
                                                ? formatNumberToAmount(filteredFareData[0].rules[0].effects[0].value)
                                                : '-'
                                        }
                                    />
                                </div>
                                <div className='w-2/5'>
                                    <Text type="label">Time Based Price</Text>
                                    <div className='p-2 bg-[#FAFAFA]'>
                                        <h5 className='text-xs'>Price(Php)</h5>
                                    </div>
                                    <div className='p-3'>
                                        <span>{filteredSurgeData[0].time_based_price_rate} per minute</span>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="flex-1 flex flex-row justify-between">
                                <div className=''>
                                    <div>
                                        <Text type="label">Surge Multiplier</Text>
                                        <Form.Item
                                            name="threshold"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Field is required',
                                                },
                                                {
                                                    type: 'number',
                                                    message: 'Must be a number',
                                                    transform: (value) => {
                                                        if (value === undefined || value === '') {
                                                            return NaN; // Handle empty cases
                                                        }
                                                        
                                                        const parsedValue = parseFloat(value);
                                                        
                                                        // Check if the value is already valid
                                                        if (!isNaN(parsedValue)) {
                                                            return parsedValue; // Return valid numbers as is
                                                        }
                                        
                                                        // Return NaN if the value cannot be parsed
                                                        return NaN;
                                                    },
                                                },
                                                {
                                                    validator: (_, value) => {
                                                        const numValue = parseFloat(value);
                                        
                                                        if (isNaN(numValue) || numValue < 0.1 || numValue > 2) {
                                                            return Promise.reject('Value must be between 0.1 and 2');
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                value={props?.surge?.threshold}
                                            />
                                        </Form.Item>
                                    </div>
                                </div >
                                <div className=''>
                                    <Text type="label">Time Base Price</Text>
                                    <Form.Item
                                        name="value"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Field is required',
                                            },
                                            {
                                                type: 'number',
                                                message: 'Must be a number',
                                                transform: (value) => {
                                                    if (value === undefined || value === '') {
                                                        return NaN; // Handle empty cases
                                                    }
                                                    
                                                    const parsedValue = parseFloat(value);
                                                    
                                                    // Check if the value is already valid
                                                    if (!isNaN(parsedValue)) {
                                                        return parsedValue; // Return valid numbers as is
                                                    }
                                    
                                                    // Return NaN if the value cannot be parsed
                                                    return NaN;
                                                },
                                            },
                                        ]}
                                    >
                                        <Input
                                            value={props?.surge?.value}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Form>

            <Modal
                title="Please Review Changes Carefully"
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                okText={isProceeding ? 'Processing...' : 'Proceed'} 
                cancelButtonProps={{ disabled: isProceeding }} 
                okButtonProps={{ disabled: isProceeding }} 
            >
                <p>The updates you're making to the fare matrix will be applied directly to the app. Please double-check all changes before saving to ensure accuracy.</p>
            </Modal>

            {props.onCreate && (
                <div className="flex gap-2 self-end mt-md">
                    <Button onClick={props.onCancel}>Cancel</Button>
                    <Button type="primary" loading={loading} onClick={handleCreate}>
                        Submit
                    </Button>
                </div>
            )}
        </div>
    );
};

export default FareConfigSurge;
